.footer{
  padding-bottom: 75px;
  @include tablet{
    padding-bottom: 25px;
  }
  &__container{
    display: flex;
    @include tablet{
      flex-wrap: wrap;
    }
  }
  &__left{
    margin-right: 70px;
    display: flex;
    @include tablet{
      margin-right: 0;
      width: 100%;
      flex-grow: 1;
      margin-bottom: 30px;
    }
    @include mobile{
      flex-wrap: wrap;
      margin-bottom: 7px;
    }
  }
  &__left-col{
    margin-right: 55px;
    @include tablet{
      flex: 1 1 50%;
      margin-right: 0;
    }
    @include mobile{
      margin-bottom: 40px;
    }
  }
  &__left-menu{
    @include tablet{
      flex: 1 1 50%;
    }
  }
  &__logo{
    width: 165px;
    height: 127px;
    display: block;
    margin-bottom: 40px;
    @include mobile{
      margin-bottom: 30px;
    }
  }
  &__left-place{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    padding-left: 35px;
    position: relative;
    max-width: 380px;
    @include mobile{
      font-size: 14px;
      line-height: 17px;
    }
    &:before{
      content: "";
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/map-pin-g.svg);
    }
  }
  &__left-link{
    display: block;
    padding-left: 35px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-decoration-line: underline;
    color: $color-accent;
    margin-bottom: 10px;
    @include mobile{
      font-size: 14px;
    }
  }
  &__left-phone{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 5px;
    padding-left: 35px;
    position: relative;
    display: block;
    &:before{
      content: "";
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/phone-g.svg);
    }
  }
  &__left-mail{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding-left: 35px;
    position: relative;
    @include mobile{
      font-size: 14px;
      line-height: 17px;
    }
    &:before{
      content: "";
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/mail-g.svg);
    }
  }
  &__left-title{
    font-family: 'PF';
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 15px;
    @include tablet{
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 10px;
    }
    @include mobile{
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__left-nav{
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #28292B;
    display: block;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    @include tablet{
      margin-bottom: 15px;
    }
    @include mobile{
      font-size: 14px;
      margin-bottom: 13px;
    }
    &:hover{
      color: $color-accent;
    }
  }
  &__left-menu{
    @include mobile{
      display: flex;
      justify-content: space-between;
    }
  }
  &__left-img{
    margin-top: 20px;
    width: 145px;
    height: 205px;
    border: 1px solid #42BFA9;
    border-radius: 5px;
    overflow: hidden;
    @include mobile{
      margin-top: 0;
      width: 103px;
      height: 144px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__form{
    background-color: $color-accent;
    border-radius: 10px;
    padding: 40px;
    flex-grow: 1;
    @include tablet{
      padding: 20px;
    }
    @include mobile{
      padding: 15px;
    }
  }
  &__form-title{
    font-family: 'PF';
    font-weight: 300;
    font-size: 32px;
    line-height: 110%;
    color: #FFFFFF;
    margin-bottom: 15px;
    max-width: 380px;
    @include tablet{
      font-size: 24px;
      max-width: 320px;
    }
    @include mobile{
      font-size: 18px;
    }
  }
  &__form-label{
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  &__form-input{
    width: 100%;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.8);
    padding: 12px 12px 12px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #fff;
    @include mobile{
      font-size: 14px;
      padding: 8px 12px 14px 0;
    }
    &::placeholder{
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: rgba(255, 255, 255, 0.8);
      @include mobile{
        font-size: 14px;
      }
    }
  }
  &__form-textarea{
    resize: none;
    height: 45px;
    box-sizing: border-box;
    padding: 11px 12px 11px 0;
    @include mobile{
      font-size: 14px;
    }
  }
  &__form-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    @include mobile{
      flex-direction: column;
    }
  }
  &__form-btn{
    @include mobile{
      width: 100%;
    }
  }
  &__form-btn--send{
    background-color: #fff;
    color: $color-accent;
    flex: 1 1 48%;
    border-radius: 10px;
    font-family: 'PF';
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    padding: 15px 15px;
    &:hover{
      color: #fff;
      background-color: #3FA996;
    }
  }
  &__form-btn--clear{
    background-color: #3FA996;
    color: #fff;
    flex: 1 1 48%;
    border-radius: 10px;
    font-family: 'PF';
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    padding: 15px 15px;
    &:hover{
      color: $color-accent;
      background-color: #fff;
    }
  }
}
