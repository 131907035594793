.tabs{
  @include tabs;
  display: flex;
  @include tablet{
    display: block;
  }
}
.advantages{
  padding: 120px 0;
  @include tablet{
    padding: 78px 0 70px;
  }
  @include mobile{
    padding: 50px 0 50px;
  }
  &__title{
    margin-bottom: 40px;
    @include tablet{
      max-width: 300px;
      margin-bottom: 20px;
    }
    @include mobile{
      margin-bottom: 10px;
    }
  }
  .tabs__nav{
    flex-direction: column;
    width: 407px;
    padding-left: 0;
    margin: 0;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 30px;
    @include tablet{
      width: 100%;
      margin-bottom: 35px;
    }
    @include mobile{
      margin-bottom: 20px;
    }
  }
  .tabs__nav-btn{
    background-color: #F1F1F1;
    font-family: 'PF';
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: #28292B;
    padding: 20px;
    display: block;
    width: 100%;
    text-align: left;
    @include tablet{
      padding: 14px 20px;
    }
    @include mobile{
      font-size: 16px;
      line-height: 19px;
      padding: 13px 20px 8px;
    }
  }
  .tabs__nav-item:not(:last-child){
    border-bottom: 1px solid #CDCDCD;
  }
  .tabs__nav-btn--active{
    color: #fff;
    background-color: $color-accent;
  }
  &__content-title{
    font-family: 'PF';
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 10px;
    @include tablet{
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 6px;
    }
    @include mobile{
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__content-subtitle{
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 20px;
    @include tablet{
      max-width: 360px;
    }
    @include mobile{
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  &__content-img{
    width: 100%;
    min-height: 325px;
    border-radius: 10px;
    overflow: hidden;
    @include tablet{
      min-height: 230px;
    }
    @include mobile{
      min-height: 164px;
      height: 164px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
