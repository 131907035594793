html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding: 0;
}
.page {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1320px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}
.btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.swiper-pagination{
  display: none;
  @include tablet{
    display: block;
  }
}
.title{
  font-family: 'PF';
  font-weight: 300;
  font-size: 50px;
  line-height: 60px;
  @include tablet{
    font-size: 40px;
    line-height: 48px;
  }
  @include mobile{
    font-size: 24px;
    line-height: 29px;
  }
}
