.delivery{
  &__container{
    text-align: center;
  }
  &__title{
    margin-bottom: 15px;
    text-align: left;
    @include mobile{
      margin-bottom: 7px;
    }
  }
  &__text{
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    max-width: 620px;
    margin-bottom: 30px;
    text-align: left;
    @include tablet{
      max-width: 600px;
      margin-bottom: 18px;
    }
    @include mobile{
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
  &__inner{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 30px;
    @include tablet{
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    }
    @include mobile{
      margin-bottom: 22px;
    }
  }
  &__item{
    display: block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 350px;
    @include tablet{
      height: 200px;
    }
    @include mobile{
      height: 130px;
    }
  }
  &__item:first-child{
    grid-column: 2 span;
    @include mobile{
      height: 150px;
    }
  }
  &__item-img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__item-name{
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 1;
    font-family: 'PF';
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    @include tablet{
      bottom: 22px;
      left: 20px;
      text-align: left;
    }
    @include mobile{
      font-size: 14px;
      line-height: 17px;
      bottom: 15px;
      left: 15px;
    }
  }
  &__btn{
    background-color: $color-accent;
    color: #fff;
    border-radius: 10px;
    font-family: 'PF';
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    padding: 15px 50px;
    @include mobile{
      width: 100%;
    }
    &:hover{
      filter:brightness(1.2);
    }
  }
  &__item-cover{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.3);
    transition: all 0.3s ease-in-out;
  }
  &__item:hover{
    .delivery__item-cover{
      opacity: 0;
    }
    .delivery__item-name{
      color: $color-accent;
    }
  }
}
