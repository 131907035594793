.brands{
  position: relative;
  padding: 120px 0;
  @include tablet{
    padding: 78px 0 78px;
  }
  @include mobile{
    padding: 50px 0 50px;
  }
  &-slide{
    height: 220px;
    background: #F5F5F5;
    border-radius: 10px;
    display: flex;
    padding: 61px 74px;
    box-sizing: border-box;
    @include tablet{
      height: 156px;
      padding: 40px 52px;
    }
    @include mobile{
      height: 70px;
      padding: 20px 15px;
    }
    img{
      width: 100%;
      height: 100%;
      @include tablet{
        object-fit: contain;
      }
    }
  }
  &-slide__img{
    width: 100%;
    height: 100%;
  }
  &__title{
    margin-bottom: 30px;
    @include tablet{
      margin-bottom: 18px;
    }
    @include mobile{
      margin-bottom: 10px;
    }
  }
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 0;
  }
  .swiper-pagination-bullet{
    width: 24px;
    height: 24px;
    border: 1px solid $color-accent;
    opacity: 1;
    background-color: transparent;
    position: relative;
  }
  .swiper-pagination-bullet-active{
    &::before{
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: $color-accent;
    }
  }
}
.brands-button-prev{
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $color-accent;
  position: absolute;
  top: 58%;
  left: calc((100vw - 1440px) / 2);
  z-index: 1;
  border: none;
  padding-top: 5px;
  padding-right: 13px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover{
    filter:brightness(1.2);
  }
}
.brands-button-next{
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $color-accent;
  position: absolute;
  top: 58%;
  right: calc((100vw - 1440px) / 2);
  z-index: 1;
  border: none;
  padding-top: 8px;
  padding-left: 13px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover{
    filter:brightness(1.2);
  }
}
.brands-slider{
  @include tablet{
    padding-bottom: 50px;
  }
  @include mobile{
    padding-bottom: 40px;
  }
}
