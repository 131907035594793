/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "PF";
  src: url("../fonts/../fonts/PF_HausSquare_Pro.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

.page {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1320px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.swiper-pagination {
  display: none;
}
@media (max-width: 1024px) {
  .swiper-pagination {
    display: block;
  }
}

.title {
  font-family: "PF";
  font-weight: 300;
  font-size: 50px;
  line-height: 60px;
}
@media (max-width: 1024px) {
  .title {
    font-size: 40px;
    line-height: 48px;
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 24px;
    line-height: 29px;
  }
}

.header-top {
  background-color: #42BFA9;
  color: #fff;
  padding: 7px 0 6px;
}
@media (max-width: 1024px) {
  .header-top {
    display: none;
  }
}
.header-top__container {
  display: flex;
  align-items: center;
}
.header-top__left {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
}
@media (max-width: 1250px) {
  .header-top__left {
    flex: 0 1 50%;
  }
}
.header-top__place {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  max-width: 230px;
  position: relative;
  padding-left: 35px;
  margin-right: 40px;
}
@media (max-width: 1250px) {
  .header-top__place {
    margin-right: 15px;
  }
}
.header-top__place:before {
  content: "";
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  top: 4px;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/map-pin.svg);
}
.header-top__time {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  position: relative;
  padding-left: 35px;
}
.header-top__time:before {
  content: "";
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  top: -4px;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/clock.svg);
}
.header-top__right {
  display: flex;
  align-items: center;
  padding-left: 15px;
  flex: 0 0 50%;
}
@media (max-width: 1250px) {
  .header-top__right {
    flex: 1 1 62%;
  }
}
.header-top__mail {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  padding-left: 35px;
  position: relative;
  margin-right: 35px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1250px) {
  .header-top__mail {
    margin-right: 15px;
  }
}
.header-top__mail:hover {
  color: #28292B;
}
.header-top__mail:before {
  content: "";
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  top: -4px;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/mail.svg);
}
.header-top__phone {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  padding-left: 35px;
  position: relative;
  margin-right: 35px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1250px) {
  .header-top__phone {
    margin-right: 15px;
  }
}
.header-top__phone:hover {
  color: #28292B;
}
.header-top__phone:before {
  content: "";
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  top: -5px;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/phone.svg);
}
.header-top__btn {
  background-color: #fff;
  font-family: "PF";
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-left: auto;
}
.header-top__btn:hover {
  background-color: #3FA996;
  color: #fff;
}

.header-bottom {
  padding: 17px 0;
  background-color: #28292B;
}
@media (max-width: 1024px) {
  .header-bottom {
    padding: 12px 0;
  }
}
.header-bottom__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-bottom__logo {
  display: block;
  width: 150px;
  height: 115px;
}
@media (max-width: 1024px) {
  .header-bottom__logo {
    width: 97px;
    height: 75px;
  }
}
@media (max-width: 576px) {
  .header-bottom__logo {
    width: 71.65px;
    height: 55px;
  }
}
.header-bottom__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.header-bottom__list {
  display: flex;
  align-items: center;
  padding-right: 12px;
}
@media (max-width: 1024px) {
  .header-bottom__list {
    display: none;
  }
}

.nav__link {
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-transform: uppercase;
  color: #FFFFFF;
}
.nav__link:hover {
  color: #42BFA9;
}
@media (max-width: 1024px) {
  .nav__link {
    font-size: 32px;
    font-weight: 700;
  }
}
@media (max-width: 576px) {
  .nav__link {
    font-size: 16px;
  }
}

.nav__item:not(:last-child) {
  margin-right: 40px;
}
@media (max-width: 1024px) {
  .nav__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.nav__link.active {
  border-bottom: 2px solid #42BFA9;
  color: #42BFA9;
}

@media (max-width: 1024px) {
  .nav__item {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .nav__item {
    margin-bottom: 15px;
  }
}

.nav__wrap {
  columns: 2;
}
@media (max-width: 576px) {
  .nav__wrap {
    columns: 1;
  }
}

.nav__btn {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  display: none;
}
@media (max-width: 1024px) {
  .nav__btn {
    display: inline-block;
  }
}
@media (max-width: 576px) {
  .nav__btn {
    padding: 18px;
  }
}

.nav__link-sub {
  position: relative;
  padding-right: 20px;
}
.nav__link-sub:after {
  content: "";
  position: absolute;
  top: 7px;
  right: 5px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 4px 0 4px;
  border-color: #fff transparent transparent transparent;
  transition: all 0.3s ease-in-out;
}

.nav__sublist {
  border-radius: 5px;
  background-color: #fff;
  padding: 15px 10px;
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  top: 18px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.33);
  z-index: 20;
  min-width: 200px;
  display: none;
}

.nav__link-sub:hover .nav__sublist {
  display: block;
}
.nav__link-sub:hover::after {
  transform: rotate(180deg);
  border-color: #42BFA9 transparent transparent transparent;
}

.nav__link-cart {
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
}
.nav__link-cart img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.nav__link-cart span {
  position: absolute;
  top: -4px;
  right: -12px;
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 6px 6px;
  background-color: #42BFA9;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .nav__link-cart span {
    top: -12px;
    right: -16px;
  }
}
@media (max-width: 1024px) {
  .nav__link-cart {
    width: 30px;
    height: 30px;
  }
}

.nav__sublink {
  display: block;
  white-space: nowrap;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  color: #28292B;
}
.nav__sublink:hover {
  color: #42BFA9;
}

.nav__item-mob {
  display: none;
}
@media (max-width: 1024px) {
  .nav__item-mob {
    display: block;
    position: absolute;
    top: 40px;
    right: 95px;
  }
}
@media (max-width: 576px) {
  .nav__item-mob {
    top: 30px;
  }
}

.burger__wrapper {
  position: absolute;
  top: 30px;
  right: 20px;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  padding: 5px 13px;
  z-index: 20;
}
@media (max-width: 1024px) {
  .burger__wrapper {
    display: block;
  }
}
@media (max-width: 576px) {
  .burger__wrapper {
    top: 20px;
  }
}

.burger {
  --burger-width: 16px;
  --burger-height: 10px;
  --burger-line-height: 2px;
  position: relative;
  border: none;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: #42BFA9;
  background-color: transparent;
  cursor: pointer;
}
.burger::before, .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger::before {
  top: 0;
}
.burger::after {
  top: calc(100% - var(--burger-line-height));
}
.burger__line {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
.burger--active::before {
  top: 50%;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active::after {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active .burger__line {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

.mob-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #28292B;
  z-index: 15;
  padding: 20px;
}

@media (max-width: 1024px) {
  .mob-menu.menu--active {
    display: block;
    overflow-y: auto;
    height: 100%;
  }
}

.mob-menu__logo {
  display: block;
  width: 122px;
  height: 94px;
  position: absolute;
  top: 20px;
  left: 20px;
}
@media (max-width: 576px) {
  .mob-menu__logo {
    width: 76px;
    height: 58.34px;
  }
}

.mob-menu__nav {
  margin-top: 145px;
}
@media (max-width: 576px) {
  .mob-menu__nav {
    margin-top: 105px;
  }
}

.mob-menu__link {
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  display: block;
  margin-bottom: 40px;
  text-align: left;
}
@media (max-width: 576px) {
  .mob-menu__link {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

.mob-menu__link-sub span {
  position: relative;
}
.mob-menu__link-sub span::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 15px;
  right: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 576px) {
  .mob-menu__link-sub span::after {
    top: 10px;
  }
}

.mob-menu__sublink {
  display: block;
  padding: 10px;
}

.mob-menu__sublist {
  display: none;
}

.mob-menu__sublist.active {
  display: block;
}

.mob-menu .footer__left-place {
  color: #fff;
}
.mob-menu .footer__left-phone {
  color: #fff;
}
.mob-menu .footer__left-mail {
  color: #fff;
}

.mob-menu__btn {
  display: inline-block;
  width: 100%;
  background-color: #fff;
  color: #28292B;
  padding: 18px;
  border-radius: 10px;
  margin-top: 40px;
  text-align: center;
  font-family: "PF";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

.tabs {
  display: flex;
}
.tabs .tabs__nav-btn--active {
  background-color: #ff0001;
}
.tabs .tabs__panel {
  display: none;
}
.tabs .tabs__panel--active {
  display: block;
}
@media (max-width: 1024px) {
  .tabs {
    display: block;
  }
}

.advantages {
  padding: 120px 0;
}
@media (max-width: 1024px) {
  .advantages {
    padding: 78px 0 70px;
  }
}
@media (max-width: 576px) {
  .advantages {
    padding: 50px 0 50px;
  }
}
.advantages__title {
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .advantages__title {
    max-width: 300px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .advantages__title {
    margin-bottom: 10px;
  }
}
.advantages .tabs__nav {
  flex-direction: column;
  width: 407px;
  padding-left: 0;
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 30px;
}
@media (max-width: 1024px) {
  .advantages .tabs__nav {
    width: 100%;
    margin-bottom: 35px;
  }
}
@media (max-width: 576px) {
  .advantages .tabs__nav {
    margin-bottom: 20px;
  }
}
.advantages .tabs__nav-btn {
  background-color: #F1F1F1;
  font-family: "PF";
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #28292B;
  padding: 20px;
  display: block;
  width: 100%;
  text-align: left;
}
@media (max-width: 1024px) {
  .advantages .tabs__nav-btn {
    padding: 14px 20px;
  }
}
@media (max-width: 576px) {
  .advantages .tabs__nav-btn {
    font-size: 16px;
    line-height: 19px;
    padding: 13px 20px 8px;
  }
}
.advantages .tabs__nav-item:not(:last-child) {
  border-bottom: 1px solid #CDCDCD;
}
.advantages .tabs__nav-btn--active {
  color: #fff;
  background-color: #42BFA9;
}
.advantages__content-title {
  font-family: "PF";
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .advantages__content-title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 6px;
  }
}
@media (max-width: 576px) {
  .advantages__content-title {
    font-size: 18px;
    line-height: 22px;
  }
}
.advantages__content-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .advantages__content-subtitle {
    max-width: 360px;
  }
}
@media (max-width: 576px) {
  .advantages__content-subtitle {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.advantages__content-img {
  width: 100%;
  min-height: 325px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .advantages__content-img {
    min-height: 230px;
  }
}
@media (max-width: 576px) {
  .advantages__content-img {
    min-height: 164px;
    height: 164px;
  }
}
.advantages__content-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.brands {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 1024px) {
  .brands {
    padding: 78px 0 78px;
  }
}
@media (max-width: 576px) {
  .brands {
    padding: 50px 0 50px;
  }
}
.brands-slide {
  height: 220px;
  background: #F5F5F5;
  border-radius: 10px;
  display: flex;
  padding: 61px 74px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .brands-slide {
    height: 156px;
    padding: 40px 52px;
  }
}
@media (max-width: 576px) {
  .brands-slide {
    height: 70px;
    padding: 20px 15px;
  }
}
.brands-slide img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .brands-slide img {
    object-fit: contain;
  }
}
.brands-slide__img {
  width: 100%;
  height: 100%;
}
.brands__title {
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .brands__title {
    margin-bottom: 18px;
  }
}
@media (max-width: 576px) {
  .brands__title {
    margin-bottom: 10px;
  }
}
.brands .swiper-horizontal > .swiper-pagination-bullets, .brands .swiper-pagination-bullets.swiper-pagination-horizontal, .brands .swiper-pagination-custom, .brands .swiper-pagination-fraction {
  bottom: 0;
}
.brands .swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  border: 1px solid #42BFA9;
  opacity: 1;
  background-color: transparent;
  position: relative;
}
.brands .swiper-pagination-bullet-active::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #42BFA9;
}

.brands-button-prev {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #42BFA9;
  position: absolute;
  top: 58%;
  left: calc((100vw - 1440px) / 2);
  z-index: 1;
  border: none;
  padding-top: 5px;
  padding-right: 13px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.brands-button-prev:hover {
  filter: brightness(1.2);
}

.brands-button-next {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #42BFA9;
  position: absolute;
  top: 58%;
  right: calc((100vw - 1440px) / 2);
  z-index: 1;
  border: none;
  padding-top: 8px;
  padding-left: 13px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.brands-button-next:hover {
  filter: brightness(1.2);
}

@media (max-width: 1024px) {
  .brands-slider {
    padding-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .brands-slider {
    padding-bottom: 40px;
  }
}

.delivery__container {
  text-align: center;
}
.delivery__title {
  margin-bottom: 15px;
  text-align: left;
}
@media (max-width: 576px) {
  .delivery__title {
    margin-bottom: 7px;
  }
}
.delivery__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  max-width: 620px;
  margin-bottom: 30px;
  text-align: left;
}
@media (max-width: 1024px) {
  .delivery__text {
    max-width: 600px;
    margin-bottom: 18px;
  }
}
@media (max-width: 576px) {
  .delivery__text {
    font-size: 14px;
    margin-bottom: 12px;
  }
}
.delivery__inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .delivery__inner {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media (max-width: 576px) {
  .delivery__inner {
    margin-bottom: 22px;
  }
}
.delivery__item {
  display: block;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 350px;
}
@media (max-width: 1024px) {
  .delivery__item {
    height: 200px;
  }
}
@media (max-width: 576px) {
  .delivery__item {
    height: 130px;
  }
}
.delivery__item:first-child {
  grid-column: 2 span;
}
@media (max-width: 576px) {
  .delivery__item:first-child {
    height: 150px;
  }
}
.delivery__item-img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
.delivery__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.delivery__item-name {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 1;
  font-family: "PF";
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .delivery__item-name {
    bottom: 22px;
    left: 20px;
    text-align: left;
  }
}
@media (max-width: 576px) {
  .delivery__item-name {
    font-size: 14px;
    line-height: 17px;
    bottom: 15px;
    left: 15px;
  }
}
.delivery__btn {
  background-color: #42BFA9;
  color: #fff;
  border-radius: 10px;
  font-family: "PF";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 50px;
}
@media (max-width: 576px) {
  .delivery__btn {
    width: 100%;
  }
}
.delivery__btn:hover {
  filter: brightness(1.2);
}
.delivery__item-cover {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}
.delivery__item:hover .delivery__item-cover {
  opacity: 0;
}
.delivery__item:hover .delivery__item-name {
  color: #42BFA9;
}

.footer {
  padding-bottom: 75px;
}
@media (max-width: 1024px) {
  .footer {
    padding-bottom: 25px;
  }
}
.footer__container {
  display: flex;
}
@media (max-width: 1024px) {
  .footer__container {
    flex-wrap: wrap;
  }
}
.footer__left {
  margin-right: 70px;
  display: flex;
}
@media (max-width: 1024px) {
  .footer__left {
    margin-right: 0;
    width: 100%;
    flex-grow: 1;
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .footer__left {
    flex-wrap: wrap;
    margin-bottom: 7px;
  }
}
.footer__left-col {
  margin-right: 55px;
}
@media (max-width: 1024px) {
  .footer__left-col {
    flex: 1 1 50%;
    margin-right: 0;
  }
}
@media (max-width: 576px) {
  .footer__left-col {
    margin-bottom: 40px;
  }
}
@media (max-width: 1024px) {
  .footer__left-menu {
    flex: 1 1 50%;
  }
}
.footer__logo {
  width: 165px;
  height: 127px;
  display: block;
  margin-bottom: 40px;
}
@media (max-width: 576px) {
  .footer__logo {
    margin-bottom: 30px;
  }
}
.footer__left-place {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
  padding-left: 35px;
  position: relative;
  max-width: 380px;
}
@media (max-width: 576px) {
  .footer__left-place {
    font-size: 14px;
    line-height: 17px;
  }
}
.footer__left-place:before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/map-pin-g.svg);
}
.footer__left-link {
  display: block;
  padding-left: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-decoration-line: underline;
  color: #42BFA9;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .footer__left-link {
    font-size: 14px;
  }
}
.footer__left-phone {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 5px;
  padding-left: 35px;
  position: relative;
  display: block;
}
.footer__left-phone:before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/phone-g.svg);
}
.footer__left-mail {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 35px;
  position: relative;
}
@media (max-width: 576px) {
  .footer__left-mail {
    font-size: 14px;
    line-height: 17px;
  }
}
.footer__left-mail:before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/mail-g.svg);
}
.footer__left-title {
  font-family: "PF";
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 15px;
}
@media (max-width: 1024px) {
  .footer__left-title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .footer__left-title {
    font-size: 18px;
    line-height: 22px;
  }
}
.footer__left-nav {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #28292B;
  display: block;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .footer__left-nav {
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .footer__left-nav {
    font-size: 14px;
    margin-bottom: 13px;
  }
}
.footer__left-nav:hover {
  color: #42BFA9;
}
@media (max-width: 576px) {
  .footer__left-menu {
    display: flex;
    justify-content: space-between;
  }
}
.footer__left-img {
  margin-top: 20px;
  width: 145px;
  height: 205px;
  border: 1px solid #42BFA9;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 576px) {
  .footer__left-img {
    margin-top: 0;
    width: 103px;
    height: 144px;
  }
}
.footer__left-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.footer__form {
  background-color: #42BFA9;
  border-radius: 10px;
  padding: 40px;
  flex-grow: 1;
}
@media (max-width: 1024px) {
  .footer__form {
    padding: 20px;
  }
}
@media (max-width: 576px) {
  .footer__form {
    padding: 15px;
  }
}
.footer__form-title {
  font-family: "PF";
  font-weight: 300;
  font-size: 32px;
  line-height: 110%;
  color: #FFFFFF;
  margin-bottom: 15px;
  max-width: 380px;
}
@media (max-width: 1024px) {
  .footer__form-title {
    font-size: 24px;
    max-width: 320px;
  }
}
@media (max-width: 576px) {
  .footer__form-title {
    font-size: 18px;
  }
}
.footer__form-label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.footer__form-input {
  width: 100%;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: rgba(255, 255, 255, 0.8);
  padding: 12px 12px 12px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #fff;
}
@media (max-width: 576px) {
  .footer__form-input {
    font-size: 14px;
    padding: 8px 12px 14px 0;
  }
}
.footer__form-input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 576px) {
  .footer__form-input::placeholder {
    font-size: 14px;
  }
}
.footer__form-textarea {
  resize: none;
  height: 45px;
  box-sizing: border-box;
  padding: 11px 12px 11px 0;
}
@media (max-width: 576px) {
  .footer__form-textarea {
    font-size: 14px;
  }
}
.footer__form-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
@media (max-width: 576px) {
  .footer__form-buttons {
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .footer__form-btn {
    width: 100%;
  }
}
.footer__form-btn--send {
  background-color: #fff;
  color: #42BFA9;
  flex: 1 1 48%;
  border-radius: 10px;
  font-family: "PF";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 15px;
}
.footer__form-btn--send:hover {
  color: #fff;
  background-color: #3FA996;
}
.footer__form-btn--clear {
  background-color: #3FA996;
  color: #fff;
  flex: 1 1 48%;
  border-radius: 10px;
  font-family: "PF";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 15px;
}
.footer__form-btn--clear:hover {
  color: #42BFA9;
  background-color: #fff;
}

.hero-slider {
  position: relative;
}
.hero-card {
  min-height: 36vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 0;
  position: relative;
}
@media (max-width: 1280px) {
  .hero-card {
    min-height: 500px;
  }
}
@media (max-width: 576px) {
  .hero-card {
    min-height: 320px;
  }
}
.hero-card__img {
  position: absolute;
  inset: 0;
  width: 100%;
  min-height: 100%;
}
@media (max-width: 1024px) {
  .hero-card__img img {
    height: 100%;
  }
}
.hero__title {
  font-family: "PF";
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 96px;
  color: #FFFFFF;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  text-align: center;
}
@media (max-width: 1024px) {
  .hero__title {
    font-size: 60px;
    line-height: 72px;
  }
}
@media (max-width: 576px) {
  .hero__title {
    font-size: 29px;
    line-height: 35px;
  }
}
.hero__form {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .hero__form {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .hero__form {
    margin-bottom: 28px;
  }
}
.hero__form-label {
  margin-right: 30px;
  position: relative;
  z-index: 1;
  flex: 1 0 625px;
}
@media (max-width: 1024px) {
  .hero__form-label {
    margin-right: 10px;
    flex-shrink: 1;
  }
}
@media (max-width: 576px) {
  .hero__form-label {
    margin-right: 5px;
  }
}
.hero__form-input {
  background-color: #fff;
  color: #28292B;
  font-family: "PF";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 30px;
  width: 100%;
  height: 60px;
}
@media (max-width: 576px) {
  .hero__form-input {
    height: 40px;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 10px;
  }
}
.hero__form-input::placeholder {
  font-family: "PF";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}
@media (max-width: 576px) {
  .hero__form-input::placeholder {
    font-size: 14px;
    line-height: 17px;
  }
}
.hero__form-btn {
  border-radius: 10px;
  color: #fff;
  background-color: #42BFA9;
  font-family: "PF";
  font-weight: 300;
  font-size: 16px;
  position: relative;
  padding: 20px 56px 20px 77px;
}
.hero__form-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 22px;
  left: 55px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/search.svg);
}
@media (max-width: 1024px) {
  .hero__form-btn::before {
    top: 21px;
    left: 35px;
  }
}
@media (max-width: 576px) {
  .hero__form-btn::before {
    top: 12px;
    left: 11px;
  }
}
@media (max-width: 1024px) {
  .hero__form-btn {
    padding: 19px 37px 20px 56px;
  }
}
@media (max-width: 576px) {
  .hero__form-btn {
    padding: 11px 10px 11px 32px;
    font-size: 14px;
    line-height: 17px;
  }
}
.hero .swiper-horizontal > .swiper-pagination-bullets, .hero .swiper-pagination-bullets.swiper-pagination-horizontal, .hero .swiper-pagination-custom, .hero .swiper-pagination-fraction {
  bottom: 28px;
}
@media (max-width: 576px) {
  .hero .swiper-horizontal > .swiper-pagination-bullets, .hero .swiper-pagination-bullets.swiper-pagination-horizontal, .hero .swiper-pagination-custom, .hero .swiper-pagination-fraction {
    bottom: 13px;
  }
}
.hero .swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  border: 1px solid #42BFA9;
  opacity: 1;
  background-color: transparent;
  position: relative;
}
.hero .swiper-pagination-bullet-active::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #42BFA9;
}

.hero-button-prev {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #42BFA9;
  position: absolute;
  top: 50%;
  left: calc((100vw - 1280px) / 2);
  z-index: 1;
  border: none;
  padding-top: 5px;
  padding-right: 13px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.hero-button-prev:hover {
  filter: brightness(1.2);
}
@media (max-width: 1280px) {
  .hero-button-prev {
    left: 0;
  }
}
@media (max-width: 1024px) {
  .hero-button-prev {
    display: none;
  }
}

.hero-button-next {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #42BFA9;
  position: absolute;
  top: 50%;
  right: calc((100vw - 1280px) / 2);
  z-index: 1;
  border: none;
  padding-top: 8px;
  padding-left: 13px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.hero-button-next:hover {
  filter: brightness(1.2);
}
@media (max-width: 1280px) {
  .hero-button-next {
    right: 0;
  }
}
@media (max-width: 1024px) {
  .hero-button-next {
    display: none;
  }
}

.graph-modal__container.modal-form {
  width: 1062px;
  padding: 50px;
}
@media (max-width: 1100px) {
  .graph-modal__container.modal-form {
    width: calc(100vw - 40px);
  }
}
@media (max-width: 576px) {
  .graph-modal__container.modal-form {
    padding: 25px 15px;
  }
}

.modal-form .graph-modal__close {
  top: 70px;
  right: 50px;
}
@media (max-width: 576px) {
  .modal-form .graph-modal__close {
    top: 30px;
    right: 15px;
  }
}

.modal__title {
  font-family: "PF";
  font-weight: 300;
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .modal__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 5px;
  }
}

.modal__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  max-width: 750px;
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .modal__text {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.modal__form-label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.modal__form-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  padding: 12px 0;
  border-bottom: 1px solid #28292B;
}
.modal__form-input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #28292B;
}
@media (max-width: 576px) {
  .modal__form-input::placeholder {
    font-size: 14px;
  }
}

.modal__form-textarea {
  height: 77px;
  resize: none;
  margin-bottom: 25px;
}

.modal__filesize {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #6A6A6A;
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .modal__filesize {
    font-size: 14px;
  }
}

.modal__subtitle {
  font-family: "PF";
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 8px;
}
@media (max-width: 576px) {
  .modal__subtitle {
    font-size: 18px;
  }
}

.modal__form-input--download {
  border-radius: 10px;
  padding: 5px 5px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 23px;
}
@media (max-width: 576px) {
  .modal__form-input--download {
    font-size: 12px;
    width: 100%;
  }
}

.form__btn {
  font-family: "PF";
  padding: 15px 55px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  background-color: #42BFA9;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #42BFA9;
}
@media (max-width: 576px) {
  .form__btn {
    width: 100%;
    padding: 15px 40px;
  }
}
.form__btn:hover {
  background-color: #fff;
  color: #42BFA9;
}