.header-top{
  background-color: $color-accent;
  color:#fff;
  padding: 7px 0 6px;
  @include tablet{
    display: none;
  }
  &__container{
    display: flex;
    align-items: center;
  }
  &__left{
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    @media (max-width: 1250px){
      flex: 0 1 50%;
    }
  }
  &__place{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    max-width: 230px;
    position: relative;
    padding-left: 35px;
    margin-right: 40px;
    @media (max-width: 1250px){
      margin-right: 15px;
    }
    &:before{
      content: "";
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      top: 4px;
      left: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/map-pin.svg);
    }
  }
  &__time{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    position: relative;
    padding-left: 35px;
    &:before{
      content: "";
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      top: -4px;
      left: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/clock.svg);
    }
  }
  &__right{
    display: flex;
    align-items: center;
    padding-left: 15px;
    flex: 0 0 50%;
    @media (max-width: 1250px) {
      flex: 1 1 62%;
    }
  }
  &__mail{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding-left: 35px;
    position: relative;
    margin-right: 35px;
    transition: all 0.3s ease-in-out;
    @media (max-width: 1250px){
      margin-right: 15px;
    }
    &:hover{
      color: $color-main;
    }
    &:before{
      content: "";
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      top: -4px;
      left: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/mail.svg);
    }
  }
  &__phone{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding-left: 35px;
    position: relative;
    margin-right: 35px;
    transition: all 0.3s ease-in-out;
    @media (max-width: 1250px){
      margin-right: 15px;
    }
    &:hover{
      color: $color-main;
    }
    &:before{
      content: "";
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      top: -5px;
      left: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/phone.svg);
    }
  }
  &__btn{
    background-color: #fff;
    font-family: 'PF';
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 20px;
    border-radius: 10px;
    margin-left: auto;
    &:hover{
      background-color: #3FA996;
      color: #fff;
    }
  }
}
.header-bottom{
  padding: 17px 0;
  background-color: $color-main;
  @include tablet{
    padding: 12px 0;
  }
  &__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo{
    display: block;
    width: 150px;
    height: 115px;
    @include tablet{
      width: 97px;
      height: 75px;
    }
    @include mobile{
      width: 71.65px;
      height: 55px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__list{
    display: flex;
    align-items: center;
    padding-right: 12px;
    @include tablet{
      display: none;
    }
  }
}

.nav__link{
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-transform: uppercase;
  color: #FFFFFF;
  &:hover{
    color: $color-accent;
  }
  @include tablet{
    font-size: 32px;
    font-weight: 700;
  }
  @include mobile{
    font-size: 16px;
  }
}
.nav__item:not(:last-child){
  margin-right: 40px;
  @include tablet{
    margin-right: 0;
    margin-bottom: 0;
  }
}
.nav__link.active{
  border-bottom: 2px solid $color-accent;
  color: $color-accent;
}
.nav__item{
  @include tablet{
    margin-bottom: 20px;
  }
  @include mobile{
    margin-bottom: 15px;
  }
}

.nav__wrap{
  columns: 2;
  @include mobile{
    columns: 1;
  }
}
.nav__btn{
  margin-top: 20px;
  width: 100%;
  text-align: center;
  display: none;
  @include tablet{
    display: inline-block;
  }
  @include mobile{
    padding: 18px;
  }
}
.nav__link-sub{
  position: relative;
  padding-right: 20px;
  &:after{
    content: "";
    position: absolute;
    top: 7px;
    right: 5px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 4px 0 4px;
    border-color: #fff transparent transparent transparent;
    transition: all 0.3s ease-in-out;
  }
}
.nav__sublist{
  border-radius: 5px;
  background-color: #fff;
  padding: 15px 10px;
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  top: 18px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.33);
  z-index: 20;
  min-width: 200px;
  display: none;
}
.nav__link-sub:hover{
  .nav__sublist{
    display: block;
  }
  &::after{
    transform: rotate(180deg);
    border-color: $color-accent transparent transparent transparent;
  }
}
.nav__link-cart{
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  span{
    position: absolute;
    top: -4px;
    right: -12px;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding: 6px 6px;
    background-color: $color-accent;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    @include tablet{
      top: -12px;
      right: -16px;
    }
  }
  @include tablet{
    width: 30px;
    height: 30px;
  }
}
.nav__sublink{
  display: block;
  white-space: nowrap;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  color: $color-main;

  &:hover{
    color: $color-accent;
  }
}
.nav__item-mob{
  display: none;
  @include tablet{
    display: block;
    position: absolute;
    top: 40px;
    right: 95px;
  }
  @include mobile{
    top: 30px;
  }
}

.burger__wrapper{
  position: absolute;
  top: 30px;
  right: 20px;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  padding: 5px 13px;
  z-index: 20;
  @include tablet{
    display: block;
  }
  @include mobile{
    top: 20px;
  }
}
.burger{
  @include burger;


}
.mob-menu{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $color-main;
  z-index: 15;
  padding: 20px;
}
.mob-menu.menu--active{
  @include tablet{
    display: block;
    overflow-y: auto;
    height: 100%;
  }
}
.mob-menu__logo{
  display: block;
  width: 122px;
  height: 94px;
  position: absolute;
  top: 20px;
  left: 20px;
  @include mobile{
    width: 76px;
    height: 58.34px;
  }
}
.mob-menu__nav{
  margin-top: 145px;
  @include mobile{
    margin-top: 105px;
  }
}
.mob-menu__link{
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  display: block;
  margin-bottom: 40px;
  text-align: left;
  @include mobile{
    font-size: 24px;
    margin-bottom: 30px;
  }
}
.mob-menu__link-sub span{
  position: relative;
  &::after{
    content: "";
    display: inline-block;
    position: absolute;
    top: 15px;
    right: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: #fff transparent transparent transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    @include mobile{
      top: 10px;
    }
  }
}
.mob-menu__sublink{
  display: block;
  padding: 10px;
}
.mob-menu__sublist{
  display: none;
}
.mob-menu__sublist.active{
  display: block;
}
.mob-menu{
  .footer__left-place{
    color: #fff;
  }
  .footer__left-phone{
    color: #fff;
  }
  .footer__left-mail{
    color: #fff;
  }
}
.mob-menu__btn{
  display: inline-block;
  width: 100%;
  background-color: #fff;
  color: $color-main;
  padding: 18px;
  border-radius: 10px;
  margin-top: 40px;
  text-align: center;
  font-family: 'PF';
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}
