.graph-modal__container.modal-form{
  width: 1062px;
  padding: 50px;
  @media (max-width: 1100px) {
    width: calc(100vw - 40px);
  }
  @include mobile{
    padding: 25px 15px;
  }

}
.modal-form .graph-modal__close{
  top: 70px;
  right: 50px;
  @include mobile{
    top: 30px;
    right: 15px;
  }
}
.modal__title{
  font-family: 'PF';
  font-weight: 300;
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 10px;
  @include mobile{
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 5px;
  }
}
.modal__text{
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  max-width: 750px;
  margin-bottom: 25px;
  @include mobile{
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.modal__form-label{
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.modal__form-input{
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  padding: 12px 0;
  border-bottom: 1px solid #28292B;
  &::placeholder{
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: $color-main;
    @include mobile{
      font-size: 14px;
    }
  }
}
.modal__form-textarea{
  height: 77px;
  resize: none;
  margin-bottom: 25px;
}
.modal__filesize{
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #6A6A6A;
  margin-bottom: 15px;
  @include mobile{
    font-size: 14px;
  }
}
.modal__subtitle{
  font-family: 'PF';
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 8px;
  @include mobile{
    font-size: 18px;
  }
}
.modal__form-input--download{
  border-radius: 10px;
  padding: 5px 5px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 23px;
  @include mobile {
    font-size: 12px;
    width: 100%;
  }
}
.form__btn{
  font-family: 'PF';
  padding: 15px 55px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  background-color: $color-accent;
  color: #fff;
  border-radius: 10px;
  border: 1px solid $color-accent;
  @include mobile{
    width: 100%;
    padding: 15px 40px;
  }
  &:hover{
    background-color: #fff;
    color: $color-accent;
  }
}
