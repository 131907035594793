.hero{
  &-slider{
    position: relative;
  }
  &-card{
    min-height: 36vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 0;
    position: relative;
    @media (max-width: 1280px) {
      min-height: 500px;
    }
    @include mobile{
      min-height: 320px;
    }
  }
  &-card__img{
    position: absolute;
    inset: 0;
    width: 100%;
    min-height: 100%;
    img{
      @include tablet{
        height: 100%;
      }
    }
  }
  &__title{
    font-family: 'PF';
    font-style: normal;
    font-weight: 300;
    font-size: 80px;
    line-height: 96px;
    color: #FFFFFF;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
    text-align: center;
    @include tablet{
      font-size: 60px;
      line-height: 72px;
    }
    @include mobile{
      font-size: 29px;
      line-height: 35px;
    }
  }
  &__form{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    @include tablet{
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    @include mobile{
      margin-bottom: 28px;
    }
  }
  &__form-label{
    margin-right: 30px;
    position: relative;
    z-index: 1;
    flex: 1 0 625px;
    @include tablet{
      margin-right: 10px;
      flex-shrink: 1;
    }
    @include mobile{
      margin-right: 5px;
    }
  }
  &__form-input{
    background-color: #fff;
    color: $color-main;
    font-family: 'PF';
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 30px;
    width: 100%;
    height: 60px;
    @include mobile{
      height: 40px;
      font-size: 14px;
      line-height: 17px;
      padding: 12px 10px;
    }
    &::placeholder{
      font-family: 'PF';
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      @include mobile{
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  &__form-btn{
    border-radius: 10px;
    color: #fff;
    background-color: $color-accent;
    font-family: 'PF';
    font-weight: 300;
    font-size: 16px;
    position: relative;
    padding: 20px 56px 20px 77px;

    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 22px;
      left: 55px;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/search.svg);
      @include tablet{
        top: 21px;
        left: 35px;
      }
      @include mobile{
        top: 12px;
        left: 11px;
      }
    }
    @include tablet{
      padding: 19px 37px 20px 56px;
    }
    @include mobile{
      padding: 11px 10px 11px 32px;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 28px;
    @include mobile{
      bottom: 13px;
    }
  }
  .swiper-pagination-bullet{
    width: 24px;
    height: 24px;
    border: 1px solid $color-accent;
    opacity: 1;
    background-color: transparent;
    position: relative;
  }
  .swiper-pagination-bullet-active{
    &::before{
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: $color-accent;
    }
  }
}
.hero-button-prev{
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $color-accent;
  position: absolute;
  top: 50%;
  left: calc((100vw - 1280px) / 2);
  z-index: 1;
  border: none;
  padding-top: 5px;
  padding-right: 13px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover{
    filter:brightness(1.2);
  }
  @media (max-width: 1280px){
    left: 0;
  }
  @include tablet{
    display: none;
  }
}
.hero-button-next{
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $color-accent;
  position: absolute;
  top: 50%;
  right: calc((100vw - 1280px) / 2);
  z-index: 1;
  border: none;
  padding-top: 8px;
  padding-left: 13px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover{
    filter:brightness(1.2);
  }
  @media (max-width: 1280px){
    right: 0;
  }
  @include tablet{
    display: none;
  }
}
